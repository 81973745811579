import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Navigation',
    component: () => import('../views/Navigation.vue'),
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('../views/main/Home.vue')
      },
      {
        path: '/athan',
        name: 'Athan',
        component: () => import('../views/main/Athan.vue')
      },
      {
        path: '/quran',
        name: 'Quran',
        component: () => import('../views/main/Quran.vue')
      },
      {
        path: '/prayer',
        name: 'Prayer',
        component: () => import('../views/main/Prayer.vue')
      },
      {
        path: '/others',
        name: 'Others',
        component: () => import('../views/main/Others.vue')
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
