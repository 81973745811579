<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Icon_Other_Apps_Dark" data-name="Icon / Other Apps / Dark" transform="translate(-897 -826)">
      <rect id="Box" width="24" height="24" transform="translate(897 826)" fill="none"/>
      <rect id="Rectangle_609" data-name="Rectangle 609" width="8" height="8" rx="2" transform="translate(900 829)" fill="#fff" stroke="currentColor" stroke-linecap="round" stroke-width="2"/>
      <rect id="Rectangle_609-2" data-name="Rectangle 609" width="8" height="8" rx="2" transform="translate(911 829)" fill="#fff" stroke="currentColor" stroke-linecap="round" stroke-width="2"/>
      <rect id="Rectangle_609-3" data-name="Rectangle 609" width="8" height="8" rx="2" transform="translate(900 840)" fill="#fff" stroke="currentColor" stroke-linecap="round" stroke-width="2"/>
      <rect id="Rectangle_609-4" data-name="Rectangle 609" width="8" height="8" rx="2" transform="translate(911 840)" fill="#fff" stroke="currentColor" stroke-linecap="round" stroke-width="2"/>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'OthersIcon',
  }
</script>
