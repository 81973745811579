export default {
  namespaced: true,
  state: {
    message: {}
  },
  mutations: {
    showMessage (state, params) {
      state.message = {
        type: params[0],
        text: params.length > 1 ? params[1] : ''
      }
    }
  }
}