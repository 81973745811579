<template lang="pug">
  v-app.frs-hide-scroll
    v-main
      v-snackbar.elevation-0(top tile flat :timeout="snackbarProp.timeout" :color="snackbarProp.color" v-model="snackbarProp.show")
        span.s1.basic100--text {{ snackbarProp.text }}
      router-view
      AudioPlayer
</template>

<script>
import { mapState } from 'vuex'
import AudioPlayer from '@/components/AudioPlayer.vue'

export default {
  name: 'App',
  components: { AudioPlayer },
  data: () => ({
    snackbarProp: {
      show: false,
      color: 'success',
      text: '',
      timeout: 3000
    }
  }),
  computed: {
    ...mapState('message', ['message'])
  },
  watch: {
    message (val) {
      if (val.type === 'success') {
        this.showSnackbarSuccess(val.text)
      } else if (val.type === 'error') {
        this.showSnackbarError(val.text)
      } else if (val.type === 'info') {
        this.showSnackbarInfo(val.text)
      } else if (val.type === 'warning') {
        this.showSnackbarWarning(val.text)
      }
    }
  },
  mounted () {
    this.$store.commit('user/recoverUser')
    this.disablePageZoom()
    // this.$plugins.addListener('WebAppBridgePluginEvent',
    //   (val) => {
    //     console.log('val: ' + val['value']);
    //     this.echo = val
    //   },
    // )
    // document.addEventListener('WebAppBridgePluginEventCreated',
    //   (val) => {
    //     console.log('val: ' + val['value']);
    //     this.echo = val['value']
    //   },
    // )
  },
  methods: {
    showSnackbarError (text) {
      this.snackbarProp.text = text
      this.snackbarProp.color = 'danger'
      this.snackbarProp.show = true
    },
    showSnackbarSuccess (text) {
      this.snackbarProp.text = text
      this.snackbarProp.color = 'success'
      this.snackbarProp.show = true
    },
    showSnackbarInfo (text) {
      this.snackbarProp.text = text
      this.snackbarProp.color = 'info'
      this.snackbarProp.show = true
    },
    showSnackbarWarning (text) {
      this.snackbarProp.text = text
      this.snackbarProp.color = 'warning'
      this.snackbarProp.show = true
    },
    disablePageZoom () {
      const tag = document.createElement('meta')
      tag.setAttribute('name','viewport')
      tag.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0')
      document.head.appendChild(tag)
    }
  }
};
</script>

<style lang="scss">

//AveriaSerifLibre
@font-face {
  font-family: 'AveriaSerifLibre';
  src: url('./assets/fonts/AveriaSerifLibre-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'AveriaSerifLibre';
  src: url('./assets/fonts/AveriaSerifLibre-Bold.ttf') format('truetype');
  font-weight: 700;
}

//Quicksand
@font-face {
  font-family: 'Quicksand';
  src: url('./assets/fonts/Quicksand-Regular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Quicksand';
  src: url('./assets/fonts/Quicksand-Bold.otf') format('opentype');
  font-weight: 700;
}

//SourceSansPro
@font-face {
  font-family: 'SourceSansPro';
  src: url('./assets/fonts/SourceSansPro-Regular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('./assets/fonts/SourceSansPro-Semibold.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('./assets/fonts/SourceSansPro-Bold.otf') format('opentype');
  font-weight: 900;
}

@font-face {
  font-family: 'MeQuran';
  font-style: normal;
  src: url('./assets/fonts/me_quran.ttf') format('truetype');
}
</style>
