<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="23.343" height="22.635" viewBox="0 0 23.343 22.635">
    <path id="search" d="M1.061,22.635H15.915a1.062,1.062,0,0,0,1.061-1.061V16.269a.2.2,0,0,0,0-.02c.039-.025.079-.048.117-.074l5.649,5.649a.354.354,0,1,0,.5-.5l-5.6-5.6a4.582,4.582,0,0,0-.67-7.219.2.2,0,0,0,0-.02V6.72c0-.012-.006-.022-.006-.033a.331.331,0,0,0-.009-.046.345.345,0,0,0-.064-.135c0-.006,0-.014-.009-.021L11.229.119l-.007,0A.347.347,0,0,0,11.064.02a.245.245,0,0,0-.04-.008A.356.356,0,0,0,10.964,0h-9.9A1.062,1.062,0,0,0,0,1.061V21.574a1.062,1.062,0,0,0,1.061,1.061Zm17.33-10.257a3.89,3.89,0,1,1-3.89-3.89A3.895,3.895,0,0,1,18.391,12.379ZM11.318,1.284l4.518,5.082H11.671c-.156,0-.354-.3-.354-.531ZM.707,1.061A.354.354,0,0,1,1.061.707H10.61V5.836a1.227,1.227,0,0,0,1.061,1.238h4.6V8.135A4.582,4.582,0,0,0,11.191,9.2H3.89a.354.354,0,0,0,0,.707h6.742a4.557,4.557,0,0,0-.712,2.122H3.89a.354.354,0,1,0,0,.707h6.03a4.565,4.565,0,0,0,.712,2.122H3.89a.354.354,0,1,0,0,.707h7.25a.3.3,0,0,0,.042-.008,4.579,4.579,0,0,0,5.086,1.068v4.953a.354.354,0,0,1-.354.354H1.061a.35.35,0,0,1-.354-.354V1.061ZM3.89,7.073H8.134a.354.354,0,0,0,0-.707H3.89a.354.354,0,0,0,0,.707Zm0,11.318h9.2a.354.354,0,1,0,0-.707H3.89a.354.354,0,1,0,0,.707Z" fill="currentColor"/>
  </svg>
</template>

<script>
  export default {
    name: 'HadisIcon',
  }
</script>
