<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22.635" height="22.635" viewBox="0 0 22.635 22.635">
    <path id="compass" d="M0,11.318A11.318,11.318,0,1,0,11.318,0,11.331,11.331,0,0,0,0,11.318ZM11.318.707A10.61,10.61,0,1,1,.707,11.318,10.622,10.622,0,0,1,11.318.707Zm0,19.806a9.2,9.2,0,1,0-9.2-9.2A9.206,9.206,0,0,0,11.318,20.513ZM10.61,2.865V3.945a.354.354,0,1,0,.707,0V2.829A8.495,8.495,0,0,1,19.77,10.61h-1.08a.354.354,0,0,0,0,.707h1.116a8.5,8.5,0,0,1-8.488,8.488V18.69a.354.354,0,1,0-.707,0V19.77a8.495,8.495,0,0,1-7.781-8.452H3.946a.354.354,0,1,0,0-.707H2.865A8.492,8.492,0,0,1,10.61,2.865ZM6.639,16.3a.354.354,0,0,0,.207-.066l3.971-2.855a2.09,2.09,0,0,0,.5.066,2.1,2.1,0,0,0,2.06-2.608l2.9-4.04a.354.354,0,0,0-.494-.494L11.7,9.237a2.094,2.094,0,0,0-.385-.039,2.112,2.112,0,0,0-2.081,2.526L6.352,15.738a.353.353,0,0,0,.287.56ZM9.9,11.321a1.415,1.415,0,1,1,1.415,1.415A1.416,1.416,0,0,1,9.9,11.321Zm4.544-3.184-1.405,1.956a2.14,2.14,0,0,0-.56-.543ZM9.553,12.5a2.141,2.141,0,0,0,.526.54L8.188,14.4Z" fill="currentColor"/>
  </svg>
</template>

<script>
  export default {
    name: 'QiblatIcon',
  }
</script>
