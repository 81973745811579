export default {
  namespaced: true,
  state: {
    currentData: {
      language: 'ms',
      appTheme: 'light',
      translations: ['ms'],
      darkTheme: false,
      showTajwid: false,
      fontSize: 20
    }
  },
  mutations: {
    recoverCurrentData (state) {
      state.currentData.language = localStorage.getItem('tilawah-language') || state.currentData.language
      state.currentData.appTheme = localStorage.getItem('tilawah-app-theme') || state.currentData.appTheme
      state.currentData.translations = JSON.parse(localStorage.getItem('tilawah-translations')) || state.currentData.translations
      state.currentData.darkTheme = localStorage.getItem('tilawah-dark-theme') === 'true'
      state.currentData.showTajwid = localStorage.getItem('tilawah-show-tajwid') === 'true'
      state.currentData.fontSize = parseInt(localStorage.getItem('tilawah-font-size')) || state.currentData.fontSize
    },
    setLanguage (state, language) {
      state.currentData.language = language
      localStorage.setItem('tilawah-language', language)
    },
    setAppTheme (state, appTheme) {
      state.currentData.appTheme = appTheme
      localStorage.setItem('tilawah-app-theme', appTheme)
    },
    setTranslations (state, translations) {
      state.currentData.translations = translations
      localStorage.setItem('tilawah-translations', JSON.stringify(translations))
    },
    setDarkTheme (state, darkTheme) {
      state.currentData.darkTheme = darkTheme
      localStorage.setItem('tilawah-dark-theme', darkTheme)
    },
    setShowTajwid (state, showTajwid) {
      state.currentData.showTajwid = showTajwid
      localStorage.setItem('tilawah-show-tajwid', showTajwid)
    },
    setFontSize (state, fontSize) {
      state.currentData.fontSize = fontSize
      localStorage.setItem('tilawah-font-size', fontSize)
    }
  },
  getters: {
    getCurrentData (state) {
      return state.currentData
    }
  }
}