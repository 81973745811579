<template lang="pug">
.audio-player
  v-bottom-sheet(hide-overlay inset persistent no-click-animation v-model="showModal" ref="bottomsheet")
    v-card.header
      v-row
        v-col.pb-0.pb-md-3(cols='12' md='6')
          h4.mx-3.text-center.text-md-left {{ playingVerse.verse && playingVerse.verse.surah.name_display }} : {{ playingVerse.verse && playingVerse.verse.verse_number }}
          .d-flex.flex-row.align-center
            h5.ma-3 {{ currentTime }}
            v-progress-linear(:value='progressValue')
            h5.ma-3 {{ totalTime }}
        v-col.pt-0.pt-md-3(cols='12' md='6')
          .d-flex.justify-center.align-center
            v-btn.mx-3(icon @click='clickRepeat')
              v-icon(v-if="shouldRepeat" color='primary') mdi-repeat
              v-icon(v-else) mdi-repeat
            v-spacer
            v-btn(icon @click='clickBackward')
              v-icon mdi-rewind
            v-btn.mx-3(icon @click='clickBackward5')
              img(:src="require('@/assets/images/replay_5.svg')")
            v-btn(icon x-large @click='clickPlayPause')
              v-icon(v-if="!isPause") mdi-pause
              v-icon(v-else) mdi-play
            v-btn.mx-3(icon @click='clickForward5')
              img(:src="require('@/assets/images/forward_5.svg')")
            v-btn(icon @click='clickForward')
              v-icon mdi-fast-forward
            v-spacer
            v-btn.mx-3(icon @click='clickStop')
              v-icon mdi-stop
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AudioPlayer',
  data: () => ({
    showModal: false,
    currentAudio: null,
    isPause: false,
    listenerAttaced: false,
    currentTime: '00:00',
    totalTime: '00:00',
    progressValue: 0,
    shouldRepeat: false
  }),
  computed: {
    ...mapGetters({
      playingVerse: 'verse/getPlayingVerse'
    })
  },
  watch: {
    'showModal' () {
      this.$nextTick(() => {
        this.$refs.bottomsheet.showScroll()
      })
    },
    'playingVerse' (val) {
      if (!val.id) {
        this.stopAudio()
        this.showModal = false
        return
      }
      this.showModal = true
      if (this.currentAudio) {
        this.stopAudio()
      }
      this.currentAudio = new Audio(this.playingVerse.url)
      this.currentAudio.play()
      this.currentAudio.onended = () => {
        this.$store.commit('verse/playVerseById', this.shouldRepeat ? this.playingVerse.id : this.playingVerse.id + 1)
      }
      if (!this.listenerAttaced) {
        this.listenerAttaced = true
        this.currentAudio.addEventListener('timeupdate', this.audioListener)
      }
    }
  },
  mounted () {
  },
  methods: {
    clickPlayPause () {
      if (this.isPause) {
        this.currentAudio.play()
      } else {
        this.currentAudio.pause()
      }
      this.isPause = !this.isPause
    },
    clickForward () {
      this.$store.commit('verse/playVerseById', this.playingVerse.id + 1)
    },
    clickBackward () {
      if (this.playingVerse.id === 1) {
        return
      }
      this.$store.commit('verse/playVerseById', this.playingVerse.id - 1)
    },
    clickStop () {
      this.$store.commit('verse/stopVerse')
    },
    stopAudio () {
      this.progressValue = 0
      this.listenerAttaced = false
      this.currentAudio.pause()
      this.currentAudio.currentTime = 0
      this.currentAudio = null
    },
    clickBackward5 () {
      this.currentAudio.currentTime = this.currentAudio.currentTime - 5
    },
    clickForward5 () {
      this.currentAudio.currentTime = this.currentAudio.currentTime + 5
    },
    clickRepeat () {
      this.shouldRepeat = !this.shouldRepeat
    },
    audioListener () {
      if (this.currentAudio == null) {
        return
      }
      const time = this.currentAudio.currentTime
      this.currentTime = this.convertTime(time)
      this.totalTime = this.convertTime(this.currentAudio.duration)
      this.progressValue = (time / this.currentAudio.duration) * 100
    },
    convertTime (value) {
      const seconds = Number(value)
      if (seconds <= 0 || isNaN(value)) {
        return '00:00'
      }
      const format = val => `0${Math.floor(val)}`.slice(-2)
      const minutes = (seconds % 3600) / 60
      return [minutes, seconds % 60].map(format).join(':')
    }
  }
}
</script>

<style scoped>
</style>
