<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="9.8" height="14" viewBox="0 0 9.8 14">
    <path id="ic_room_24px" d="M9.9,2A4.9,4.9,0,0,0,5,6.9C5,10.575,9.9,16,9.9,16s4.9-5.425,4.9-9.1A4.9,4.9,0,0,0,9.9,2Zm0,6.65A1.75,1.75,0,1,1,11.65,6.9,1.751,1.751,0,0,1,9.9,8.65Z" transform="translate(-5 -2)" fill="currentColor"/>
  </svg>
</template>

<script>
  export default {
    name: 'LocationIcon',
  }
</script>
