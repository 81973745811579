<template>
  <svg id="Icon_Pray_Filled" data-name="Icon / Pray / Filled" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <rect id="Box" width="24" height="24" fill="none"/>
    <path id="icon_pray" data-name="icon / pray" d="M9.605,7.057a2,2,0,1,1,2.79-.49,2.013,2.013,0,0,1-2.79.49Zm2.608,10.166L7.437,15.211l1.678-3.4,1.55,4.279a1.183,1.183,0,0,0,1.4.49,1.112,1.112,0,0,0,.762-1.221l-1.681-5.215a7.618,7.618,0,0,0-.858-2,2.738,2.738,0,0,0-1.175-.8c-.792-.311-1.8.275-2.464,1.648L4.025,14.405A2.5,2.5,0,0,0,5.53,17.68l2.726.649s.359-.368-4.231-.368a1.373,1.373,0,0,0-.97,1.281,1.278,1.278,0,0,0,.97,1.228h8.189a1.862,1.862,0,0,0,1.157-1.623A1.864,1.864,0,0,0,12.214,17.223Z" transform="translate(3.532 0.056)" fill="currentColor"/>
  </svg>
</template>

<script>
  export default {
    name: 'PrayerIcon',
  }
</script>
