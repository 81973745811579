<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Icon_Time_Filled" data-name="Icon / Time / Filled" style="isolation: isolate">
      <rect id="Box" width="24" height="24" fill="none"/>
      <g id="Ellipse_85" data-name="Ellipse 85" transform="translate(2 2)" fill="#fff" stroke="currentColor" stroke-width="2">
        <circle cx="10" cy="10" r="10" stroke="none"/>
        <circle cx="10" cy="10" r="9" fill="none"/>
      </g>
      <path id="jarum_pendek" data-name="jarum pendek" d="M0,0V4" transform="translate(12 7.5)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="1.5"/>
      <path id="jarum_panjang" data-name="jarum panjang" d="M3.5,4,0,0" transform="translate(12 12)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="1.5"/>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'AthanIcon',
  }
</script>
