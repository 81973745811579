import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

import HomeIcon from '@/components/icons/HomeIcon.vue'
import AthanIcon from '@/components/icons/AthanIcon.vue'
import QuranIcon from '@/components/icons/QuranIcon.vue'
import PrayerIcon from '@/components/icons/PrayerIcon.vue'
import OthersIcon from '@/components/icons/OthersIcon.vue'
import SurahNumberIcon from '@/components/icons/SurahNumberIcon.vue'
import LocationIcon from '@/components/icons/LocationIcon.vue'
import HalalFoodIcon from '@/components/icons/HalalFoodIcon.vue'
import CalendarIcon from '@/components/icons/CalendarIcon.vue'
import QiblatIcon from '@/components/icons/QiblatIcon.vue'
import HadisIcon from '@/components/icons/HadisIcon.vue'

Vue.use(Vuetify);

export default new Vuetify({
  treeShake: true,
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#9500FF',
        primaryDark: '#672CBA',
        textBlack: '#546576',
        textGrey: '#546576',
        textYellow: '#F7DA64',
        basic: '#000000'
      }
    }
  },
  icons: {
    values: {
      'icon-home': {
        component: HomeIcon
      },
      'icon-athan': {
        component: AthanIcon
      },
      'icon-quran': {
        component: QuranIcon
      },
      'icon-prayer': {
        component: PrayerIcon
      },
      'icon-others': {
        component: OthersIcon
      },
      'icon-surah-number': {
        component: SurahNumberIcon
      },
      'icon-location': {
        component: LocationIcon
      },
      'icon-halal-food': {
        component: HalalFoodIcon
      },
      'icon-calendar': {
        component: CalendarIcon
      },
      'icon-qiblat': {
        component: QiblatIcon
      },
      'icon-hadis': {
        component: HadisIcon
      }
    },
  },
});
