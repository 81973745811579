export default {
  namespaced: true,
  state: {
    user: null
  },
  mutations: {
    recoverUser (state) {
      const userString =  localStorage.getItem('spi_user')
      if (userString) {
        state.user = JSON.parse(userString)
      }
    },
    setUser (state, user) {
      state.user = user
      if (user) {
        localStorage.setItem('spi_user', JSON.stringify(state.user))
      } else {
        localStorage.removeItem('spi_user')
      }
    }
  },
  getters: {
    getUser (state) {
      return state.user
    }
  }
}