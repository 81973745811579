import Vue from 'vue'
import Vuex from 'vuex'
import message from './modules/message'
import user from './modules/user'
import verse from './modules/verse'
import current from './modules/current'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: { message, user, verse, current }
})

window.$store = store

export default store

