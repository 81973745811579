<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="29" height="22.635" viewBox="0 0 29 22.635">
    <path id="map" d="M6.535,22.583c.006,0,.015,0,.021.006a.361.361,0,0,0,.163.045.354.354,0,0,0,.093-.013l7.687-2.1,7.687,2.1a.354.354,0,0,0,.093.013.348.348,0,0,0,.163-.046c.007,0,.015,0,.021-.006l6.366-3.89a.354.354,0,0,0,.156-.4L25.462,5.916a.353.353,0,0,0-.205-.231.36.36,0,0,0-.308.018l-3.51,1.969a.354.354,0,1,0,.347.617L24.9,6.539l3.329,11.69-5.669,3.465L21.218,10.92a.358.358,0,0,0-.4-.307.354.354,0,0,0-.307.395L21.864,21.8l-7.01-1.913V17.957a.354.354,0,0,0-.707,0v1.931L7.137,21.8,8.485,11.008a.354.354,0,0,0-.7-.088L6.437,21.694.768,18.229,4.1,6.54,7.216,8.291a.354.354,0,1,0,.347-.617L4.053,5.7a.353.353,0,0,0-.513.212L.014,18.295a.352.352,0,0,0,.156.4l6.365,3.89Zm7.726-6.054a.355.355,0,0,0,.477,0c.236-.214,5.775-5.3,5.775-10.519a6.012,6.012,0,1,0-12.025,0C8.488,11.144,14.025,16.311,14.261,16.529ZM14.5.707a5.189,5.189,0,0,1,5.305,5.305c0,4.313-4.239,8.727-5.3,9.766C13.441,14.727,9.2,10.255,9.2,6.012A5.189,5.189,0,0,1,14.5.707Zm3.183,5.305A3.183,3.183,0,1,0,14.5,9.2,3.187,3.187,0,0,0,17.684,6.012ZM14.5,8.488a2.476,2.476,0,1,1,2.476-2.476A2.478,2.478,0,0,1,14.5,8.488Z" transform="translate(-0.001)" fill="currentColor"/>
  </svg>
</template>

<script>
  export default {
    name: 'HalalFoodIcon',
  }
</script>
