<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24.05" height="22.635" viewBox="0 0 24.05 22.635">
    <path id="calendar" d="M24.05,21.574V8.134a.354.354,0,1,0-.707,0v13.44a.354.354,0,0,1-.354.354H1.061a.354.354,0,0,1-.354-.354V8.134a.354.354,0,0,0-.707,0v13.44a1.062,1.062,0,0,0,1.061,1.061H22.989A1.062,1.062,0,0,0,24.05,21.574ZM3.183,16.269a.354.354,0,1,0,0,.707H4.244v2.476a.354.354,0,0,0,.707,0V16.976H9.2v2.476a.354.354,0,0,0,.707,0V16.976h4.244v2.476a.354.354,0,0,0,.707,0V16.976H19.1v2.476a.354.354,0,0,0,.707,0V16.976h1.061a.354.354,0,0,0,0-.707H19.806V12.732h1.061a.354.354,0,0,0,0-.707H19.806V8.842a.354.354,0,1,0-.707,0v3.183H14.854V8.842a.354.354,0,1,0-.707,0v3.183H9.9V8.842a.354.354,0,0,0-.707,0v3.183H4.951V8.842a.354.354,0,0,0-.707,0v3.183H3.183a.354.354,0,1,0,0,.707H4.244v3.537ZM19.1,12.732v3.537H14.854V12.732Zm-4.951,0v3.537H9.9V12.732Zm-9.2,0H9.2v3.537H4.951ZM20.159,2.122h3.006c.062,0,.177,0,.177.354V5.659H.707V2.476a.354.354,0,0,1,.354-.354H3.89a.354.354,0,0,0,0-.707H1.061A1.062,1.062,0,0,0,0,2.476V6.012a.354.354,0,0,0,.354.354H23.7a.354.354,0,0,0,.354-.354V2.476c0-.782-.457-1.061-.884-1.061H20.159a.354.354,0,0,0,0,.707Zm-4.244,0a.354.354,0,1,0,0-.707H8.134a.354.354,0,1,0,0,.707ZM6.366,3.183V.354a.354.354,0,0,0-.707,0V3.183a.354.354,0,0,0,.707,0Zm11.671.354a.354.354,0,0,0,.354-.354V.354a.354.354,0,1,0-.707,0V3.183A.354.354,0,0,0,18.037,3.537Z" fill="currentColor"/>
  </svg>
</template>

<script>
  export default {
    name: 'CalendarIcon',
  }
</script>
