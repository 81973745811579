<template>
  <svg id="Icon_Quran_Filled" data-name="Icon / Quran / Filled" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <rect id="Box" width="24" height="24" fill="none"/>
    <path id="icon_quran" data-name="icon / quran" d="M21,5a11.8,11.8,0,0,0-3.5-.5A9.3,9.3,0,0,0,12,6,9.3,9.3,0,0,0,6.5,4.5,9.3,9.3,0,0,0,1,6V20.65a.538.538,0,0,0,.5.5c.1,0,.15-.05.25-.05A12.205,12.205,0,0,1,6.5,20,9.3,9.3,0,0,1,12,21.5,12.045,12.045,0,0,1,17.5,20a10.178,10.178,0,0,1,4.75,1.05.451.451,0,0,0,.25.05.538.538,0,0,0,.5-.5V6A6.821,6.821,0,0,0,21,5Zm0,13.5a11.62,11.62,0,0,0-3.5-.5A12.045,12.045,0,0,0,12,19.5V8a12.045,12.045,0,0,1,5.5-1.5A11.62,11.62,0,0,1,21,7Z" transform="translate(0 -1)" fill="currentColor"/>
  </svg>
</template>

<script>
  export default {
    name: 'QuranIcon',
  }
</script>
